header h1 {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 1.8em;
}

header {
  border-bottom: 1px solid var(--border);
}

form h2:first-child {
  margin-top: 0;
  text-align: center;
}

form input {
  width: 100%;
  box-sizing: border-box;
}

.funding-progress-bar {
  margin: 1em 0em;
}

form {
  border: 1px solid var(--focus);
  padding: 1em 1em;
  margin: 1em 1em;
}

.funding-countdown {
  text-align: center;
  font-size: 2.2em;
}

#contact {
  border: 1px solid var(--border);
  object-fit: cover;
  padding: 1em;
}

#contact h2:first-child {
  text-align: center;
  margin-top: 0;
}

#contact .portrait {
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}

.error {
  border: 2px solid #ff6060;
  margin: 1em;
  padding: 1em;
  border-radius: 12px;
}

.fine-print {
  margin: 0 0 1em 0;
  border-radius: 12px;
  font-size: small;
}

.project {
  border: 1px solid var(--border);
  padding: 0px 24px;
  margin: 24px 0;
}

.project .funding-countdown {
  text-align: left;
  font-size: 1.1em;
}

.project .portrait {
  margin: 24px auto 12px auto;
  display: block;
  border-radius: 50%;
  float: none;
}

@media (min-width: 640px) {
  .project .portrait {
    float: left;
    margin-right: 24px;
    margin-bottom: 16px;
    border-radius: 0%;
  }
}

.project .refund-bonus {
  color: var(--text-bright);
  font-weight: bold;
  font-size: small;
  text-align: center;
  border: 3px dotted var(--border);
}

@media (min-width: 640px) {
  .project .refund-bonus {
    float: right;
    margin-top: 0;
    max-width: 17ex;
  }
}
